<template>
  <section class="grid cor-painel">
    <div class="field col-12 sm:col-3 md:col-2 lg:col-2 xl:col-1" v-if="anoALL">
      <label>Ano:</label>
      <select
        disabled
        class="p-inputtext p-component"
        style="appearance: revert !important; width: 100% !important"
        v-model="info.ano"
      >
        <option v-for="a in anoALL" :key="a.ano" :value="a.ano">
          {{ a.ano }}
        </option>
      </select>
    </div>
    <div class="field col-12 sm:col-3 md:col-2 lg:col-2 xl:col-2" v-if="anoALL">
      <label>Selecione o Semestre:</label>
      <i class="pi pi-exclamation-circle ml-2" v-tooltip="'Preenchimento obrigatório para buscar alunos do EJA'" style="font-size: 1rem !important"></i>
      <select
        class="p-inputtext p-component"
        style="appearance: revert !important; width: 100% !important"
        v-model="info.semestre"
      >
        <option v-for="a in semestres" :key="a.id" :value="a.id">
          {{ a.label }}
        </option>
      </select>
    </div>

    <div class="field col-12 sm:col-6 md:col-5 lg:col-4 xl:col-2">
      <label>Selecione a Escola</label>
      <select
        class="p-inputtext p-component"
        v-model="info.escola"
        @change="zerarInputFiltro(), buscarSegmentoALL()"
        style="appearance: revert !important; width: 100% !important"
      >
        <option :value="info.escola" disabled selected>{{info.escola.nome}}</option>
        <option v-for="n in escolaALL" :key="n.id" :value="n">
          {{ n.nome }}
        </option>
      </select>
    </div>

    <div
      class="field col-12 sm:col-3 md:col-3 lg:col-2 xl:col-2"
      v-if="objeto === true"
    >
      <label>Selecione o Segmento</label>
      <select
        class="p-inputtext p-component"
        v-model="info.segmento_id"
        @change="listaSerie(info.segmento_id.id)"
        style="appearance: revert !important; width: 100% !important"
      >
        <option :value="info.segmento_id" disabled selected>{{info.segmento_id.nome}}</option>
        <option v-for="n in segmentoALL2" :key="n" :value="n">
          {{ n.nome }}
        </option>
      </select>
    </div>

    <div
      class="field col-12 sm:col-3 md:col-2 lg:col-2 xl:col-2"
      v-if="objeto === true"
    >
      <label>Selecione a Serie</label>
      <select
        class="p-inputtext p-component"
        v-model="info.serie_id"
        @change="listaTurmas()"
        style="appearance: revert !important; width: 100% !important"
      >
        <option :value="info.serie_id" disabled selected>{{info.serie_id.nome}}</option>
        <option v-for="n in serieALL" :key="n" :value="n">
          {{ n.nome }}
        </option>
      </select>
    </div>

    <div class="field col-12 sm:col-3 md:col-2 lg:col-2 xl:col-2">
      <label>Selecione a Turma</label>
      <select
        class="p-inputtext p-component"
        v-model="info.turma_id"
        @change="listaDisciplinas(info.turma_id.id)"

        style="appearance: revert !important; width: 100% !important"
      >
        <option :value="info.turma_id" disabled selected>{{info.turma_id.nome}}</option>
        <option v-for="n in turmaALL" :key="n" :value="n">
          {{ n.nome }}
        </option>
      </select>
    </div>


    <div class="field col-12 sm:col-3 md:col-2 lg:col-2 xl:col-2" v-if="info.segmento_id.tipo === 2 || (info.segmento_id.tipo === 3 && info.serie_id.tipo ===2)">
      <label>Selecione a disciplina</label>
      <select
        class="p-inputtext p-component"
        v-model="info.disciplina"
        style="appearance: revert !important; width: 100% !important"
      >
        <option :value="info.disciplina" disabled selected>{{info.disciplina.nome}}</option>
        <option v-for="n in disciplinaALL" :key="n" :value="n">
          {{ n.nome }}
        </option>
      </select>
    </div>

    <div class="col-12 grid justify-content-end m-0" style="margin-top: 31px">

      <button
        class="btn btn-secondary mr-2"
        @click="limparFiltro"
      >
        Limpar filtro
      </button>
      <button
        v-if="aguardeLoading == false && info.individual === '0'"
        class="btn btn-primary"
        @click="buscarRelatorio()"
      >
        Pesquisar
      </button>
      <button
        v-if="aguardeLoading == false && info.individual === '1'"
        class="btn btn-primary"
        @click="buscarRelatorio()"
      >
        Pesquisar
      </button>

      <button disabled v-if="aguardeLoading == true" class="btn btn-primary">
        Aguarde...
      </button>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import { Escola } from "@/class/escolas";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { DisciplinaEscolar } from "@/class/disciplina";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { LoginInfo } from "@/class/login";

export default defineComponent({
  components: {},
  props: {
    modelo: 0,
    individual: 0,
    filtro: [],
    objeto: false,
  },
  data() {
    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      home: { icon: "pi pi-home", to: "/relatorios/ficha-individual" },
      items_bread: [{ label: "Transportes Alunos" }],
      escolaALL: [],
      segmentoALL: [],
      segmentoALL2: [],
      serieALL: [],
      turmaALL: [],
      disciplinaALL: [],
      anoAll: [],
      semestres: [
        {
          id: 1,
          label: "1° Semestre"
        },
        {
          id: 2,
          label: "2° Semestre"
        }
      ],
      info: {
        ano: 2023,
        individual: "0",
        escola:  {
          id:0,
          nome: "Escolha uma escola"
        },
        segmento_id:  {
          id:0,
          nome: "Escolha um segmento"
        },
        aluno: '',
        serie_id:  {
          id:0,
          nome: "Escolha uma série"
        },
        turma_id: {
          id:0,
          nome: "Escolha uma turma"
        },
        disciplina: {
          id:0,
          nome: "Escolha uma disciplina"
        },
        semestre: 1,
      },
      aguardeLoading: false,
      funcao: 0,
      who: [],
      diretor: [],
      coordenador: [],
      aluno: {
        nome: "",
        sobrenome: "",
      },
      pesquisa: null,
      escola:0,
    };
  },
  async beforeMount() {
    this.info.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
    await this.logado();
    this.anosCalendario();
    this.buscarEscolaALL();
    //this.buscarSegmentoALL();
    this.info.filtro = this.filtro;
  },
  methods: {
    limparFiltro(){

      this.info = {
        ano: 2023,
        individual: "0",
        escola:  {
          id:0,
          nome: "Escolha uma escola"
        },
        segmento_id:  {
          id:0,
          nome: "Escolha um segmento"
        },
        aluno: '',
        serie_id:  {
          id:0,
          nome: "Escolha uma série"
        },
        turma_id: {
          id:0,
          nome: "Escolha uma turma"
        },
        disciplina: {
          id:0,
          nome: "Escolha uma disciplina"
        },
        semestre: 1,
      },

      this.aluno = {
        nome: "",
        sobrenome: "",
      };
    },
    zerarInputFiltro() {

      this.info.segmento_id = {
        id:0,
        nome: "Escolha um segmento"
      },
      this.info.serie_id = {
        id:0,
        nome: "Escolha uma série"
      },
      this.info.turma_id = {
        id:0,
        nome: "Escolha uma turma"
      },
      this.info.disciplina = {
        id:0,
        nome: "Escolha uma disciplina"
      }
    },
    zerarInputAluno() {
      this.info.aluno = '';
      this.aluno.nome = "";
      this.aluno.sobrenome = "";
    },
    async logado() {
      const token = sessionStorage.getItem("token");
      const whoiam = await LoginInfo.WhoIam(token);

      this.funcao = whoiam.data.funcao;
      this.who = whoiam.data;
    },

    async anosCalendario() {
      const dt = await Calendario.ano();
      this.anoALL = dt.data;
      this.anoALL.sort((a, b) => (a.ano > b.ano ? 1 : b.ano > a.ano ? -1 : 0));
    },

    async buscarEscolaALL() {
      this.escolaALL = [];
      //gestores
      if (this.funcao === 1 || this.funcao === 2 || this.funcao === 7) {
        const data = await Escola.obtemTodos();
        this.escolaALL = data.data;
      }
      //diretores
      if (this.funcao === 3 || this.who.diretor === 1) {
        await axios
          .get("/diretor/servidor/" + this.who.usuario.id + '/ano/'+ this.info.ano)
          .then((resp) => {
            this.diretor = resp.data.id;
          })
          .catch((err) => {});

        const data = await Escola.obtemUm(this.diretor.escola);
        this.escolaALL.push(data.data);
      }
      //coordenadores
      if (this.funcao === 4) {
        const storageEscolas = sessionStorage.getItem("escolas");
        if (!storageEscolas) {
          await axios
            .get("/coordenador/servidor/" + this.who.usuario.id + '/ano/'+ this.info.ano)
            .then((resp) => {
              this.coordenador = resp.data;
              sessionStorage.setItem(
                "coordenador_r",
                JSON.stringify(this.coordenador)
              );
            })
            .catch((err) => {});

          const escolas = this.coordenador.esc;
          for (let i = 0; i < escolas.length; i++) {
            const data = await Escola.obtemUm(escolas[i]);
            this.escolaALL.push(data.data);
          }
          sessionStorage.setItem("escolas", JSON.stringify(this.escolaALL));
        } else {
          this.escolaALL = JSON.parse(storageEscolas);

          const storageCoordenador = sessionStorage.getItem("coordenador_r");
          this.coordenador = JSON.parse(storageCoordenador);
        }
      }
    },

    async buscarSegmentoALL() {
      this.segmentoALL2 = []
      const data = await SegmentoEscolar.obtemSegmentosEscola(this.info.escola.id);
      this.segmentoALL2 = data.data;

      // if (this.funcao === 4) {
      //   const data = await SegmentoEscolar.obtemSegmentosEsp();
      //   this.segmentoALL2 = data.data;
      // } else {
      //   const data = await SegmentoEscolar.obtemTodos();
      //   this.segmentoALL2 = data.data;
      // }
    },

    async listaSerie(id) {
      // this.info.segmento_id = 0
      this.info.turma_id = {
        id: 0,
        nome: "Escolha uma turma"
      };
      this.info.serie_id = {
        id: 0,
        nome: "Escolha uma série"
      };
      this.info.disciplina = {
        id:0,
        nome: "Escolha uma disciplina"
      }
      this.serieALL = [];
      //const data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      const data = await SegmentoEscolar.listarSegmentosPorSerieExistente(this.info.escola.id,id);
      this.serieALL = data.data;
      return this.seriesALL;
    },
    async listaDisciplinas(id) {
      this.info.disciplina = {
        id:0,
        nome: "Escolha uma disciplina"
      }
      const data = await DisciplinaEscolar.obtemTodosSomenteTrue2(id);
      this.disciplinaALL = data.data.disciplinas_turma;
      return this.disciplinaALL;
    },

    async listaTurmas() {
      let play;
      this.info.turma_id = {
        id:0,
        semestre:1
      };
      this.info.disciplina = {
        id:0,
        nome: "Escolha uma disciplina"
      }
      if (this.objeto === true) {
        play = {
          escola: this.info.escola.id,
          serie: this.info.serie_id.id,
          segmento: this.info.segmento_id.id,
        };
      } else {
        play = {
          escola: this.info.escola.id,
          serie: this.info.serie_id,
          segmento: this.info.segmento_id,
        };
      }

      const data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;
    },

    buscarRelatorio() {
      if (this.info.segmento_id.tipo === 2 || (this.info.segmento_id.tipo === 3 && this.info.serie_id.tipo === 2)) {
        if (this.info.escola.id != 0 && this.info.segmento_id.id != 0 && this.info.serie_id.id != 0 && this.info.turma_id.id != 0 && this.info.disciplina.id != 0) {
          this.aguardeLoading = true;
          this.$emit("buscarRelatorio", this.info);
          setInterval(() => {
            this.aguardeLoading = false;
          }, 2000);
        } else {
          this.$vaToast.init({
            message: "Preencha todos os filtros.",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "danger",
          });
        }
      } else {
        if (this.info.escola.id != 0 && this.info.segmento_id.id != 0 && this.info.serie_id.id != 0 && this.info.turma_id.id != 0) {
          this.aguardeLoading = true;
          this.$emit("buscarRelatorio", this.info);
          setInterval(() => {
            this.aguardeLoading = false;
          }, 2000);
        } else {
          this.$vaToast.init({
            message: "Preencha todos os filtros.",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "danger",
          });
        }
      }

    },
    newfiltro(filtro) {
      this.info.filtro = filtro;
    },
  },
});
</script>

<style scoped>
.cor-painel {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}
</style>
