<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="" style="margin-top: -15px; padding: 14px">
      <div class="row">
        <div class="col-md-10">
          <h2 class="titulo-links-escolha">Resultados Finais</h2>
        </div>
      </div>

      <filtroResultadosFinais
        modelo="0"
        individual="1"
        :objeto="true"
        @buscarRelatorio="buscarRelatorio"
        :filtro="filtro"
      />
    </div>
    <button
      class="btn btn-danger button-generate"
      @click="pdf()"
      title="Gerar Impressão"
      v-if="dados.length > 0"
    >
      <i class="fa fa-file-pdf-o"></i> Pdf</button
    >
    <button class="btn btn-success button-generate" @click="gerarExcel()" style="margin-top:10px;"
      v-if="dados.length > 0"
      title="Gerar Excel">
      <i class="fa fa-file-excel-o"></i> Excel
    </button>

    <button class="btn btn-secondary button-generate" @click="gerarCsv('printficha')" style="margin-top:10px;"
      v-if="dados.length > 0"
      title="Gerar csv">
      <i class="fa fa-file-o"></i> CSV
    </button>
    <br />
    <div class="mt-5 flex justify-content-center" v-if="isLoading">
      <pm-ProgressSpinner />
    </div>

    <div id="printficha" v-if="dados.length > 0 && !isLoading" class="mt-5">
      <!-- <div id="printficha"  class="mt-5"> -->
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
        <meta charset="utf-8">
      </head>

      <fundamental1 :info="info" :dados="dados" :cabecalho="cabecalho" :observacoes="observacoes" :key="componentKey"/>
      <creche :info="info" :dados="dados" :cabecalho="cabecalho" :observacoes="observacoes" :key="componentKey"/>
      <fundamental2 :info="info" :dados="dados" :cabecalho="cabecalho" :observacoes="observacoes" :key="componentKey"/>
      <eja1 :info="info" :dados="dados" :cabecalho="cabecalho" :observacoes="observacoes" :key="componentKey"/>
      <eja2 :info="info" :dados="dados" :cabecalho="cabecalho" :observacoes="observacoes" :key="componentKey"/>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import filtroResultadosFinais from "./components/filtros/filtroResultadosFinais.vue";
import fundamental1 from "./components/resultados_finais/fundamental1";
import eja1 from "./components/resultados_finais/eja1";
import eja2 from "./components/resultados_finais/eja2";
import fundamental2 from "./components/resultados_finais/fundamental2";
import creche from "./components/resultados_finais/creche.vue";
import { ResultadosFinais } from "./services/resultadosFinais.service";
import { SeriesEscolar } from "@/class/serie";

import axios from "axios";
import router from "@/router";

export default defineComponent({
  components: {
    filtroResultadosFinais,
    fundamental1,
    eja1,
    eja2,
    fundamental2,
    creche
  },
  data() {
    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      home: { icon: "pi pi-home", to: "/relatorios/ficha-individual" },
      items_bread: [{ label: "Resultados Finais" }],
      visivel: false,
      config:[],
      filtro: [
        {
          nome: "Nome",
          check: true,
          _id: 0,
        },
        {
          nome: "Filiação",
          check: true,
          _id: 1,
        },
      ],
      dados: [],
      observacoes: [],
      info: {
        aluno:'',
        escola:0,
        segmento_id: 0,
        semestre: 1,
        serie_id: 0,
        turma_id: {
          id:0,
          semestre:1
        },
        disciplina: {
          id:0,
          nome: "Escolha uma disciplina"
        },
      },
      series: [],
      componentKey: 0,
      cabecalho: {},
      isLoading: false,
      prevRoute: null,

    };
  },

  methods: {
     gerarCsv(id) {
      // Obtém a div pelo id
      const div = document.getElementById(id);
      if (!div) {
        console.error('Div não encontrada: ' + id);
        return;
      }

      // Obtém todas as linhas da div
      const linhas = div.querySelectorAll('tr');

      // Cria o conteúdo do CSV
      let csv = '';
      linhas.forEach((linha) => {
        const celulas = linha.querySelectorAll('td');
        const valores = Array.from(celulas).map((celula) => celula.textContent);
        csv += valores.join(',');
        csv += '\n';
      });

      // Cria um Blob com o conteúdo do CSV
      const csvString = "\uFEFF" + csv;
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      // Cria uma URL para o Blob
      const url = URL.createObjectURL(blob);

      // Cria um link para download do arquivo CSV
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'resultadosfinais.csv');

      // Adiciona o link ao corpo do documento e inicia o download
      document.body.appendChild(link);
      link.click();

      // Remove o link do corpo do documento
      document.body.removeChild(link);
    },
   gerarExcel(){
      const div = document.getElementById('printficha');
      const divp = document.getElementById("printme");

      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      const a = document.createElement('a');
      const data_type = 'data:application/vnd.ms-excel';
      const table_html = divp.outerHTML.replace(/ /g, '%20');
      a.href = data_type + ', ' + table_html;
      a.download = 'resultadosfinais.xls';
      a.click();
    },
    pdf() {
      document.getElementById('printme').innerHTML = '';
      const div = document.getElementById("printficha");
      const divp = document.getElementById("printme");
      if (divp.innerHTML) {
        divp.innerHTML = "";

      }
      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      window.print();
    },
    async getSeriesSegmento(segmentoId) {
      try {
        const data = await SeriesEscolar.buscaPorSegmento(segmentoId);

        this.series = data.data;
      } catch (error) {
      }
    },

    async buscarRelatorio(info) {
      this.dados = [];
      this.info = info;
      if (this.info.segmento_id.tipo === 1 || this.info.segmento_id.tipo === 0) {
        await this.getResultadosFinais(
          this.info.turma_id.id,
          // 1,
        );
      } else {
        await this.getResultadosFinais(
          this.info.turma_id.id,
          this.info.disciplina.id,
        );
      }

    },

    async getResultadosFinais(turmaId, disciplinaId)  {
      try {
        this.dados = [];
        this.cabecalho = {};
        this.isLoading = true;
        const data = await ResultadosFinais.buscaResultadosFinais(
          turmaId,
          disciplinaId
        );
        this.componentKey += 1;

        this.dados = data.data.data;
        this.observacoes = data.data.observacoes;
        this.cabecalho = data.data.cabecalho[0];
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
      const divAta = document.getElementById("print");

      if ((vm.prevRoute.name === "atas" || vm.prevRoute.name === "ataAlunos") && divAta) {
        router.go(0);
      }
    })
  },
  async beforeMount() {
    document.getElementById('printme').innerHTML = '';
  },
});
</script>

<style scoped>
.img {
  width: 100%;
}
.borda-externa {
  border: 2px #000 solid;
}

.button-generate {
  margin-top: 10px;
  margin-right: 5px;
}
.select {
  appearance: revert;
  width: 100%;
}
/* .cor-titulo {
  background-color: #292828 !important;
  color: #fff;
} */

.uppercase {
  text-transform: uppercase;
}
.tamanho-titulos-dias {
  width: 3%;
}
.alinhamento {
  margin-top: -15px;
  padding: 14px;
}

.alinhameneto-margem {
  margin-left: -12px;
  margin-right: -12px;
}

.card-filtro {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  margin-top: 15px;
}
</style>

<style>
p {
  margin-bottom: 0px !important;
}
#noprint {
  display: block;
}
#printme {
  display: none;
}

@media print {
  @page {
    /* size: A4 landscape !important; */
    margin-top: 5px !important;

    /* margin-right: 5px; */
  }
  .table-separate{
    page-break-after: always !important;
  }
  .font {
    font-size: 17px !important;
  }
  .font-cabecalho {
    font-size: 20px !important;
  }
  #noprint {
    display: none;
  }
  #printme {
    display: block;
  }
}
</style>
