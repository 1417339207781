<template>
  <div
    v-if="info.segmento_id.tipo === 3 && info.serie_id.tipo === 2"
    class="grid col-12 font table-separate"
    style="margin-bottom: 10px; padding: 10px"

  >
    <div class="grid justify-content-center align-items-center col-12" v-if="cabecalho">
      <img
        style="width: auto; height: 100px;"
        :src="base_url + '/storage/' + cabecalho.logo"
        alt=""
        class="img"
      />
      <div
        style="margin: 0px !important; text-align: center;"
        v-html="cabecalho.texto_logo"
        class="cabecalho font-cabecalho mr-5 col-12"
      ></div>
    </div>
    <table class="col-12 mt-2 borda-externa" border="1" style="min-width: 1500px;">
      <thead class="cor-titulo">
        <tr align="center">
          <th rowspan="2" class="bordas">Alunos</th>
          <th colspan="1" class="bordas">1° BIMESTRE</th>
          <th colspan="1" class="bordas" >2° BIMESTRE</th>
          <th colspan="1" class="bordas" >MÉDIA ANUAL</th>
          <th colspan="6" class="bordas">FREQUÊNCIA</th>
        </tr>
        <tr align="center" v-if="dados[0]">
          <td class="bordas" v-for="(primeiro_bimestre, index) in Object.keys(dados[0].primeiro_bimestre)" :key="index">{{ primeiro_bimestre }}</td>
          <td class="bordas" v-for="(segundo_bimestre, index) in Object.keys(dados[0].segundo_bimestre)" :key="index">{{ segundo_bimestre }}</td>
          <td class="bordas" v-for="(md, index) in Object.keys(dados[0]['MD'])" :key="index">{{ md }}</td>
          <td class="bordas" v-for="(frequencia, index) in Object.keys(dados[0].frequencia)" :key="index">
            <span v-if="frequencia !== 'aprovado_em_frequencia'">{{ frequencia }}</span>
          </td>
          <td class="bordas" >RF</td>
        </tr>
      </thead>

      <tbody class="borda-externa" v-for="(item, index) in dados"
        :key="index">
        <tr align="center">
          <td style="text-align: left;" class="bordas">{{ item.nome }}</td>
          <td class="bordas" v-for="(valor_primeiro_bimestre, index) in item.primeiro_bimestre" :key="index">{{ valor_primeiro_bimestre }}</td>
          <td class="bordas" v-for="(valor_segundo_bimestre, index) in item.segundo_bimestre" :key="index">{{ valor_segundo_bimestre }}</td>
          <td class="bordas" v-for="(valor_md, index) in item['MD']" :key="index">
            <span v-if="valor_md >= 50" style="color: blue;">{{ valor_md }}</span>
            <span v-if="valor_md < 50" style="color: red;">{{ valor_md }}</span>
          </td>
          <td class="bordas" v-for="(valor_frequencia, index) in item.frequencia" :key="index">
            <span v-if="valor_frequencia.hasOwnProperty('total_presencas')">
              {{ valor_frequencia.total_presencas }}
            </span>
            <span v-else>

              {{ item.frequencia['FF'] }}
            </span>
          </td>
          <td class="bordas" style="color: blue;" v-if="item['RF'] === 'PR'">{{item['RF'] }}</td>
          <td class="bordas" style="color: red;" v-else-if="item['RF'] === 'RET'">{{item['RF'] }}</td>
          <td class="bordas" v-else>{{item['RF'] }}</td>

        </tr>


      </tbody>
    </table>

    <table class="col-12  " border="1" style="min-width: 1500px;">
      <thead class="cor-titulo">
        <tr align="center">
          <th rowspan="2" style="text-align: left;" class="borda-externa">Observação:</th>
          <th colspan="5" class="borda-externa">Aulas Dadas: </th>
        </tr>
      </thead>

      <tbody class="borda-externa" >
        <tr align="center">
          <td style="text-align: left;" class="borda-externa">
            <p  v-for="(item, index) in observacoes" :key="index">
              <b>{{ item.nome }}:</b> {{ item.observacao }}
            </p>
          </td>
          <td style="text-align: center; width: 300px;" class="bordas">{{ dados[0]['AULAS DADAS']}}</td>
        </tr>
      </tbody>
    </table>
    <table class="col-12 borda-externa" style="min-width: 1500px;">
      <tbody>
        <td colspan="10" align="right" style="padding-top: 10px;">
          Cardoso Moreira, ________de______________ de dois mil e vinte e
          ____________.
        </td>
        <tr align="center">
          <td style="padding-top: 20px;">___________________</td>
          <td style="padding-top: 20px;">___________________</td>
          <td style="padding-top: 20px;">___________________</td>
          <td style="padding-top: 20px;">___________________</td>
        </tr>
        <tr align="center">
          <td>Professor (a)</td>
          <td>Secretário (a)</td>
          <td>Diretor (a)</td>
          <td>Supervisor (a)</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent, } from "vue";
import { nomenclaturaSituacaoAluno } from '@/utils/nomenclatura';
import axios from "axios";


export default defineComponent({
  components: {},
  props: {
    cabecalho: {},
    dados: "",
    observacoes: "",
    info: {},
  },
  data() {
    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      nomenclaturaSituacaoAluno: nomenclaturaSituacaoAluno,


      height: 0,
    };
  },


});
</script>

<style scoped>
/* .responsive-font {
  font-size: 10px;
} */
.borda-externa {
  border: 2px #000 solid;
}
.borda-right {
  border-right: 2px #000 solid;
  /* width: 50%; */
}
.borda-right-1px {
  border-right: 1px solid #000;
}
.borda-bottom {
  border-bottom: 2px #000 solid;
}
.borda-bottom-1px {
  border-bottom: 1px solid #000;
}
.borda-top-1px {
  border-top: 1px #000 solid;
}
.borda-top {
  border-top: 2px #000 solid;
}
.bordas {
  border: 1px solid #000;
}

.title-padding {
  padding: 0px 5px 0px 5px;
}
.tabela {
  max-height: 2px;
  /* margin-bottom: 30px;
  border-collapse: collapse; */
}

.button-generate {
  margin-top: 10px;
  margin-right: 5px;
}
.select {
  appearance: revert;
  width: 100%;
}
.uppercase {
  text-transform: uppercase;
}
.tamanho-titulos-dias {
  width: 3%;
}
.alinhamento {
  margin-top: -15px;
  padding: 14px;
}

.alinhameneto-margem {
  margin-left: -12px;
  margin-right: -12px;
}

.card-filtro {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  margin-top: 15px;
}
</style>
